import React, { useEffect } from "react"
import queryString from 'query-string'
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import { Throbber } from "@soundtrackyourbrand/ui/src/BaseThrobber"
import { Section } from '@soundtrackyourbrand/ui/src/Section'

import { tracking, createTrackingEvent, isBrowser } from "lib/tracking"

const CALENDLY_URL = 'https://calendly.com/assisted-sales?primary_color=f23440'

const CALENDLY_EVENT = {
  PAGE_VIEW: 'calendly.profile_page_viewed',
  ZONES_SELECTED: 'calendly.event_type_viewed',
  DATE_SELECTED: 'calendly.date_and_time_selected',
  SCHEDULED: 'calendly.event_scheduled',
}

const BookADemo = ({ block }) => {
  const title = block.title;
  const subtitle = block.subtitle;
  const [calendlyUrl, setCalendlyUrl] = React.useState('')

  useEffect(() => {

    const handleCalendlyEvents = (e) => {
      if (isCalendlyEvent(e)) {
        switch(e.data.event) {
          case CALENDLY_EVENT.PAGE_VIEW:
            return createTrackingEvent('Storefront - Book a Demo - View')
          case CALENDLY_EVENT.ZONES_SELECTED:
            return createTrackingEvent('Storefront - Book a Demo - Number of Zones Selected')
          case CALENDLY_EVENT.DATE_SELECTED:
            return createTrackingEvent('Storefront - Book a Demo - Date Selected')
          case CALENDLY_EVENT.SCHEDULED:
            return createTrackingEvent('Storefront - Book a Demo - Completed')
          default:
            return null
        }
        
      }
    }
    let script = null

    if (isBrowser()) {
      script = document.createElement('script');

      script.src = "https://assets.calendly.com/assets/external/widget.js";
      script.async = true;
  
      document.body.appendChild(script);
  
    
      window.addEventListener('message', handleCalendlyEvents)
    }
    
  
    return () => {
      if (isBrowser()) {
        window.removeEventListener('message', handleCalendlyEvents)
        document.body.removeChild(script);
      }
    }
  }, []);

  React.useEffect(() => {
    if(isBrowser()) {
      tracking.then(t => {
        const { utm } = t
        setCalendlyUrl(`${CALENDLY_URL}&${queryString.stringify({
          utm_source: utm.source,
          utm_medium: utm.medium,
          utm_campaign: utm.campaign,
          utm_content: utm.content,
        })}`)
      })
    }
  })

  return (
    <Section className="p0">
      <div className="m-8 sm-12">
        {title && <h3 className="mb-5">{title}</h3>}
        {subtitle && <p className="subtle">{subtitle}</p>}
      </div>
      {calendlyUrl
        ? <div
            className="calendly-inline-widget"
            data-url={calendlyUrl}
            style={{minWidth: '320px', height: '950px'}}
          />
        : <Throbber />}
    </Section>
  );
};

export default BookADemo;

BookADemo.propTypes = {
  block: PropTypes.shape({
    title: PropTypes.string,
    subtile: PropTypes.string
  }),
};

function isCalendlyEvent(e) {
  return (
    e.origin === 'https://calendly.com' &&
    e.data.event &&
    e.data.event.indexOf('calendly.') === 0
  )
}

export const query = graphql`
  fragment BookADemo on DatoCmsBookademo {
    model {
      apiKey
    }
    title
    subtitle
    id: originalId
  }
`